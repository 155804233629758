import React from "react";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `<span class="h1-span">Flutter </span><span class="h1-span"> Development Company</span>`,
    content:
      "As a leading Flutter development company, we prioritize your clients' needs, creating popular solutions tailored to their requirements and significantly benefit your business in multiple ways.",
  };

  const commonContent6 = {
    title: "Flutter Development Services",
    para: "We provide Flutter development services to create a dynamic, highly functional application with native performance that meets your business needs.",
    img: ["what-service-image-flutter.webp"],
    imageTitle: 'Flutter Development',
    imageAlt: 'We are a leading Flutter development company',
    content: [
      {
        title: "Custom App Development",
        text: "With our bespoke Flutter app development services, you may receive fully specialized services that bring you closer to the needs of your target audience.",
      },
      {
        title: "Application Migration",
        text: "Need help with migration? Our Flutter development agency will assist you with a smooth conversion of your current app from native to Flutter.",
      },
      {
        title: "Flutter App Consulting",
        text: "As a leading Flutter development agency, we can help you validate your app development concept and ensure you receive the finest technical solution.",
      },
      {
        title: "Flutter Team Augmentation",
        text: "Choose the most dedicated Flutter app developer who can help you achieve your project goals at a reasonable rate.",
      },
      {
        title: "Multi-Platform App Development",
        text: "Our Flutter app development agency will use its considerable industry experience to assist you in creating and launching a fully functional multi-platform application.",
      },
      {
        title: "Support and Maintenance",
        text: "As one of our valued customers, you will receive comprehensive support and maintenance for your Flutter app from our skilled team.",
      },
    ],
  };

  const commonContent = {
    title: "Serving Industries with Flutter App Development Services",
    para: "Our Flutter Development Company is formed to match your organization's needs, allowing you to include cutting-edge functionality.",
    img: ["who-needs-flutter.webp"],
    imageTitle: 'Flutter App Development',
    imageAlt: 'Driving industry growth with our Flutter app development services',
    content: [
      {
        title: "E-commerce",
        text: "Companies seek to create dynamic and highly functional websites to give seamless website experiences to their customers.",
      },
      {
        title: "SMBs",
        text: "Companies are looking to boost their online presence using low-cost alternatives for designing websites based on consumer requirements.",
      },
      {
        title: "Startups",
        text: "Companies looking to grow their business by embracing cutting-edge tools and technology.",
      },
      {
        title: "Enterprises",
        text: "Companies that wish to simplify their complex platform while providing their clients with a high-performing, reliable platform for a better experience.",
      },
    ],
  };

  const commonContent2 = {
    title: "Achieve Efficiency with the Best Flutter App Development Company",
    para: "As the best Flutter app development company, we try to achieve the best possible results for the projects we design and create.",
    img: ["what-are-benefits-flutter-services.webp"],
    imageTitle: 'Best Flutter Development Agency',
    imageAlt: 'choose better by choosing Octet',
    content: [
      {
        title: "Increased Efficiency",
        text: "Our Flutter mobile app development services provide reusable components and streamline workflows, reducing development time and increasing productivity.",
      },
      {
        title: "Scalability",
        text: "Our Flutter development services provide scalable solutions for your business, keeping your website updated with the latest trends while ensuring performance.",
      },
      {
        title: "Enhanced User Experience",
        text: "Flutter app development services help create visually interactive, functional, responsive websites that improve user experience.",
      },
      {
        title: "Extensive Ecosystem",
        text: "Our Flutter app development company offers an innovative solution for your business through its extensive plugins, community-driven tools, and libraries.",
      },
    ],
  };
  const commonContent3 = {
    title: "Create Next-gen Apps with our Flutter Development Agency",
    linkText: ``,
    para: `Octet Design Studio is a top Flutter development agency that creates innovative interfaces using our significant industry knowledge. We use our Flutter app development services expertise to develop customized and robust online solutions for your company.`,
    img: ["why-opt-for-flutter.webp"],
    imageTitle: 'Top Flutter App Development Company',
    imageAlt: 'Work with the best Flutter development company',
    content: [
      {
        title: "Expertise And Experience",
        text: "We have appropriate expertise and experience in providing Flutter Development and better-than-expected results.",
    },
    {
      title: "Timely Delivery",
      text: "We streamline the development process to ensure your projects run smoothly and on time.",
    },
      {
          title: "Dedicated Team",
          text: "We provide a specialized team for your Flutter development, who will turn your ideas into reality and ensure timely delivery.",
        },
        {
          title: "Collaborative Approach",
          text: "We take a personalized approach to each project and provide tailored Flutter mobile app development services depending on your business objectives.",
        },
    ],
  };
  const commonContent4 = {
    title: " Process Followed by our Top Flutter App Development Company",
    para: "your project requirements, ensuring seamless integration and business success.",
    img: ["how-we-conduct-flutter-services.webp"],
    imageTitle: 'Flutter Process',
    imageAlt: 'Octet’s Flutter development process',
    content: [
      {
        title: "1. Define UX and Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Create HTML Templates",
        text: "We transform the UI design into HTML templates, which are then translated into Flutter pages.",
      },
      {
        title: "2. Create UI Designs",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "4. Convert to Webflow Pages",
        text: "Finally, we transfer the HTML code to the Flutter framework for easy comprehension and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "Why Invest in the Best Flutter App Development Agency?",
    para: "Flutter is a progressive framework for developing dynamic and interactive interfaces. Investing in our best Flutter Development Agency enables your company to create cost-effective, high-quality websites.",
    content: [
      {
        title: "30% Less Development Time",
        text: "Flutter app Development’s simplicity and reactivity can help accelerate development cycles, lowering development time and other resource expenditures.",
      },
      {
        title: "20% Code Reusability",
        text: "The Flutter website development framework's component-based architecture promotes code reuse and maintainability, potentially increasing returns.",
      },
      {
        title: "10% Increased Productivity",
        text: "Flutter app development’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      }, 
      {
        title: "10% Increased Productivity",
        text: "Flutter’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them.",
  };
  const cta = {
    title: "Expand Business Horizons with our Flutter Development Agency",
  };
  const cta2 = {
    title: "Obtain Bespoke Industry Expertise",
  };
  const cta3 = {
    title: "Build your Next Flutter Project with us",
  };
  const cta4 = {
    title: "Complete your Project Goals with Our Flutter Agency",
  };
  const cta5 = {
    title: "Let’s Develop your Next Flutter App",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
        title: "Survey2Connect",
        desc: "A Research Tech Platform",
        Industry: "Enterprise",
        firstAlt: "UI UX Design To Create Survey",
        firstTitle: "Survey Creation Screen",
        secondAlt: "Ui Design To Create Questionnaire For Survey",
        secondTitle: "Questionnaire For Survey",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "UI Development",
            link: "/ui-development/",
          },
        ],
        link: "/project/survey2connect/",
        images: ["survey.webp", "survey-2.webp"],
    },
    {
        title: "Elastic Sign",
        desc: "A Digital Signature Platform",
        Industry: "SaaS",
        firstAlt: "UI Design Of Document Management Screen",
        firstTitle: "Document Management Screen Design",
        secondAlt: "UI UX Design To Sign Document Digitally",
        secondTitle: "E-Sign Documents",
        WhatWeDid: [
          {
            title: "User Research",
            link: "/user-research/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "React Development",
            link: "/reactjs-development/",
          },
        ],
        link: "/project/elastic-sign/",
        images: ["elastic-sign.webp", "elastic-sign-2.webp"],
    },
  ];

  const Questions = [
    "Can you provide custom Flutter App Development Services?",
    "Can you integrate Flutter into existing web applications?",
    "How does your Flutter Development Company improve user experience?",
    "What is the duration and cost of your Flutter Development Services?",
  ];
  const clientsTitle = "Trusted By Clients Worldwide";
  const faqDes =
    `We understand the questions you may have concerning our Flutter Development Services. We want to share additional information about our top Flutter Development Company in this area. However, if you still have any questions, please <a title='contact us' target="_blank" rel="noreferrer" href="/contact-us/">contact us</a>.`;
  
    const faqData = [
      {
        para: [
          `Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.`,
          `Flutter development is a flexible framework we use for customization, offering robust web and app solutions.`,
          `To offer tailor-made Flutter development services and enhance your user experience, we understand your business objectives, target audience, and specific project requirements.`
        ],
      },
      {
        para: [
          `Yes, we can seamlessly integrate our Flutter app Development Services into existing applications. Because of the platform's versatility and flexibility, Flutter is the best option for enhancing the functionality of your application.`,
          `It assists in improving your business's performance, enhances user experience, and offers easily scalable options.`
        ],
      },
      {
        para: [
          `Our Flutter development company enables us to provide highly creative and responsive user interfaces based on your business requirements.`,
          `We develop dynamic websites through Flutter and update them using the latest technologies to create an engaging interface.`,
          `With our flexible approach and library, we offer optimized websites, improved usability, and an intuitive user experience.`
        ],
      },
      {
        para: [
          `The duration and cost of our Flutter Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you <a title='contact us' target="_blank" rel="noreferrer" href="/contact-us/">contact us</a> for an accurate timeline and detailed quotation.`
        ],
      }
    ];
  
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
             {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Can you provide custom Flutter App Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.
              Flutter development is a flexible framework we use for customization, offering robust web and app solutions.
              To offer tailor-made Flutter development services and enhance your user experience, we understand your business objectives, target audience, and specific project requirements."
                  }
                },{
                  "@type": "Question",
                  "name": "Can you integrate Flutter into existing web applications?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we can seamlessly integrate our Flutter app Development Services into existing applications because of the platform's versatility and flexibility.
              Flutter is the best option for enhancing the functionality of your application.
              It assists in improving your business's performance, enhances user experience, and offers easily scalable options."
                  }
                },{
                  "@type": "Question",
                  "name": "How does your Flutter Development Company improve user experience?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our Flutter development company enables us to provide highly creative and responsive user interfaces based on your business requirements.
              We develop dynamic websites through Flutter and update them using the latest technologies to create an engaging interface.
              With our flexible approach and library, we offer optimized websites, improved usability, and an intuitive user experience."
                  }
                },{
                  "@type": "Question",
                  "name": "What is the duration and cost of your Flutter Development Services?"
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The duration and cost of our Flutter Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you contact us for an accurate timeline and detailed quotation."
                  }
                }]
              }                                        
            `}
          </script>
        </Helmet>
        <Layout>
          <div className="service-detail-page">
            <Banner content={banner} page={"service"} />
            <CommonServiceComponent data={commonContent6} mode={"grey"} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent} mode={"light"} />
            <Cta mode="grey" data={cta} />
            <CommonServiceComponent data={commonContent2} mode={"dark"} />
            <CommonServiceComponent data={commonContent3} mode={"light"} />
            <Cta data={cta2} />
            <Clients title={clientsTitle} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent4} mode={"light"} />
            <Cta mode="grey" data={cta3} />
            <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
              <div className="container">
                <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                    Our Successful Projects by <span className="h1-span">Flutter Development</span>
                </h2>
              </div>
              {projectData.map((data, index) => (
                <div key={index}>
                  <ProjectSection num={index} lastLength={projectData} data={data} />
                </div>
              ))}
            </section>
            <Testimonials title={"Client’s Take On Us"} />
            <Cta data={cta4} />
            <CommonServiceComponent data={commonContent5} condition mode={"light"} />
            <ServiceTab tabs={tabs} mode={"split"} />
            <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
            <Cta mode="grey" data={cta5} />
            {/* <KnowMoreSection /> */}
          </div>
        </Layout>
      </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="Flutter Development Company | Flutter Development Services" description="Build a high-performance mobile app with our Flutter Development Company, which offers a wide range of Flutter app design and development services." />
)